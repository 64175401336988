<div
  class="tw-flex tw-w-full tw-justify-end tw-gap-x-2"
  *transloco="let t"
  data-testid="modalActions"
>
  @if (shouldShowCancelButton()) {
    <app-button
      [backgroundColor]="'transparent'"
      [isDisabled]="!canModalBeClosed()"
      (click)="onCloseButtonClick()"
      data-testid="modalCancelActionButton"
    >
      {{ areActionButtonsPresent() ? t('shared.cancel') : t('shared.close') }}
    </app-button>
  }
  <ng-content />
</div>
