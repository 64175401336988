import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChildren,
  inject,
  signal,
} from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ModalActionDirective } from '../../../directives/modal-action/modal-action.directive';
import { ButtonComponent } from '../../button/button.component';

/*
 * @example
 * <app-modal-actions>
 *   <app-button
 *     [backgroundColor]="'transparent'"
 *     [isDisabled]="false"
 *     (click)="onSave()"
 *     appModalAction
 *   >
 *     {{ t('save') }}
 *  </app-button>
 * </app-modal-actions>
 *
 */
@Component({
  selector: 'app-modal-actions',
  templateUrl: './modal-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TranslocoDirective],
})
export class ModalActionsComponent {
  readonly #dialogReference = inject(DialogRef);

  readonly canModalBeClosed = signal(true);
  readonly shouldShowCancelButton = signal(true);
  readonly contentChildren = contentChildren(ModalActionDirective, {
    descendants: true,
  });

  readonly areActionButtonsPresent = computed(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => this.contentChildren().length > 0,
  );

  configureView(
    canModalBeClosed: boolean,
    shouldShowCancelButton: boolean,
  ): void {
    this.canModalBeClosed.set(canModalBeClosed);
    this.shouldShowCancelButton.set(shouldShowCancelButton);
  }

  onCloseButtonClick(): void {
    if (!this.canModalBeClosed()) {
      return;
    }

    this.#dialogReference.close();
  }
}
