<div
  class="tw-h-full tw-w-full tw-font-sans tw-text-14 tw-font-normal tw-text-black"
  [ngClass]="{
    'tw-overflow-auto': hasOverflow(),
    'tw-overflow-hidden': !hasOverflow(),
  }"
  data-testid="modalContent"
>
  <ng-content />
</div>
