import { DialogRef } from '@angular/cdk/dialog';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  effect,
  HostListener,
  inject,
  input,
} from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { CustomTitleDirective } from '../../directives/custom-title/custom-title.directive';
import { IconButtonNewComponent } from '../icon-button-new/icon-button-new.component';
import { IconComponent } from '../icon/icon.component';
import { ModalActionsComponent } from './modal-actions/modal-actions.component';

type ModalVersion = '1.0.0' | '2.0.0';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CustomTitleDirective,
    IconButtonNewComponent,
    IconComponent,
    NgClass,
    TranslocoDirective,
  ],
})
export class ModalComponent {
  readonly #dialogReference = inject(DialogRef);

  /**
   * @deprecated
   */
  readonly canBeClosed = input(true);
  readonly shouldShowCancelButton = input(true);
  readonly version = input<ModalVersion>('1.0.0');

  readonly modalActionsComponent = contentChild(ModalActionsComponent);

  readonly shouldShowCloseIconButton = computed(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => this.modalActionsComponent() === undefined,
  );

  constructor() {
    effect(() => {
      const modalActionsComponent = this.modalActionsComponent();
      if (modalActionsComponent !== undefined) {
        modalActionsComponent.configureView(
          this.canBeClosed(),
          this.shouldShowCancelButton(),
        );
      }
    });
  }

  @HostListener('keydown.esc')
  onEscapeKeydown(): void {
    this.#close();
  }

  onCloseIconButtonClick(): void {
    this.#close();
  }

  #close(): void {
    if (!this.canBeClosed()) {
      return;
    }

    this.#dialogReference.close();
  }
}
